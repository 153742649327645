/* eslint-disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  _Any: { input: any; output: any; }
  _FieldSet: { input: any; output: any; }
};

export type AboutTranslation = {
  __typename?: 'AboutTranslation';
  buttonLink: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type AcademyTranslation = {
  __typename?: 'AcademyTranslation';
  buttonLink: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Action = {
  __typename?: 'Action';
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  privateVariables: Scalars['String']['output'];
  variables: Scalars['String']['output'];
};

export type ActiveReferralsTranslation = {
  __typename?: 'ActiveReferralsTranslation';
  title: Scalars['String']['output'];
};

export type Affiliate = User & {
  __typename?: 'Affiliate';
  commissions: PaginatedCommission;
  created: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  info: AffiliateInfo;
  kaid: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  payments: PaginatedPayment;
  paymentsSum: Scalars['Int']['output'];
  referrals: PaginatedReferral;
  referredPageviews: PaginatedReferredPageview;
  referredUrls?: Maybe<PaginatedReferredUrl>;
  referredVisitors: PaginatedReferredVisitor;
  role: Role;
  settings: AffiliateSettings;
  sumCommissions?: Maybe<PaginatedSumCommissions>;
  tags?: Maybe<Array<Tag>>;
  translation: Translation;
};


export type AffiliateCommissionsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliatePaymentsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliatePaymentsSumArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};


export type AffiliateReferralsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliateReferredPageviewsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliateReferredUrlsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliateReferredVisitorsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliateSumCommissionsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type AffiliateTagsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};

export type AffiliateApprovedWithReferral = {
  __typename?: 'AffiliateApprovedWithReferral';
  created: Scalars['String']['output'];
  kaid: Scalars['String']['output'];
  referralsCount?: Maybe<Scalars['String']['output']>;
  status?: Maybe<AffiliateStatus>;
};

export enum AffiliateCategory {
  FREELANCE_DEVELOPER_DESIGNER = 'FREELANCE_DEVELOPER_DESIGNER',
  MARKETING_AGENCY = 'MARKETING_AGENCY',
  OTHER = 'OTHER',
  PUBLISHER_BLOGGER = 'PUBLISHER_BLOGGER',
  THEME_PLUGIN = 'THEME_PLUGIN',
  WEB_DEVELOPMENT_DESIGN_AGENCY = 'WEB_DEVELOPMENT_DESIGN_AGENCY'
}

export type AffiliateCategoryOption = {
  __typename?: 'AffiliateCategoryOption';
  custom_subcategory: Scalars['Boolean']['output'];
  label: Scalars['String']['output'];
  sub_categories?: Maybe<Array<AffiliateSubCategoryOption>>;
  value: AffiliateCategory;
};

export type AffiliateCategoryTranslation = {
  __typename?: 'AffiliateCategoryTranslation';
  freelanceDeveloperDesigner: Scalars['String']['output'];
  marketingAgency: Scalars['String']['output'];
  other: Scalars['String']['output'];
  publisherBlogger: Scalars['String']['output'];
  themePlugin: Scalars['String']['output'];
  webDevelopmentDesignAgency: Scalars['String']['output'];
};

export type AffiliateCountryOption = {
  __typename?: 'AffiliateCountryOption';
  label: Scalars['String']['output'];
  value: Scalars['String']['output'];
};

export type AffiliateInfo = {
  __typename?: 'AffiliateInfo';
  adultContent: Scalars['Boolean']['output'];
  agreeToCoupons: Scalars['Boolean']['output'];
  agreeToNewsletter: Scalars['Boolean']['output'];
  agreeToNoPPC: Scalars['Boolean']['output'];
  agreeToTermination: Scalars['Boolean']['output'];
  agreeToTerms: Scalars['Boolean']['output'];
  description: Scalars['String']['output'];
  existingCustomer: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  interestedInApplicationReferrals?: Maybe<Scalars['Boolean']['output']>;
  interestedInDatabaseReferrals?: Maybe<Scalars['Boolean']['output']>;
  interestedInWordpressReferrals?: Maybe<Scalars['Boolean']['output']>;
  notSureReferInterest?: Maybe<Scalars['Boolean']['output']>;
  notes?: Maybe<Scalars['String']['output']>;
  useAddons: Scalars['Boolean']['output'];
  useCoupons: Scalars['Boolean']['output'];
};

export type AffiliateInfoInput = {
  adultContent: Scalars['Boolean']['input'];
  agreeToCoupons: Scalars['Boolean']['input'];
  agreeToNewsletter: Scalars['Boolean']['input'];
  agreeToNoPPC: Scalars['Boolean']['input'];
  agreeToTermination: Scalars['Boolean']['input'];
  agreeToTerms: Scalars['Boolean']['input'];
  description: Scalars['String']['input'];
  existingCustomer: Scalars['Boolean']['input'];
  interestedInApplicationReferrals?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInDatabaseReferrals?: InputMaybe<Scalars['Boolean']['input']>;
  interestedInWordpressReferrals?: InputMaybe<Scalars['Boolean']['input']>;
  notSureReferInterest?: InputMaybe<Scalars['Boolean']['input']>;
  useAddons: Scalars['Boolean']['input'];
  useCoupons: Scalars['Boolean']['input'];
};

export type AffiliateInput = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  info: AffiliateInfoInput;
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  settings: AffiliateSettingsInput;
};

export type AffiliateSettings = {
  __typename?: 'AffiliateSettings';
  category?: Maybe<AffiliateCategory>;
  categoryName?: Maybe<Scalars['String']['output']>;
  commissionPercentage: Scalars['Int']['output'];
  country?: Maybe<Scalars['String']['output']>;
  countryName?: Maybe<Scalars['String']['output']>;
  customSubCategory?: Maybe<Scalars['String']['output']>;
  discovery?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  isPaymentOnHold: Scalars['Boolean']['output'];
  language: Language;
  mkCookie: Scalars['Boolean']['output'];
  paypalEmail: Scalars['String']['output'];
  status: AffiliateStatus;
  subCategory?: Maybe<AffiliateSubCategory>;
  subCategoryName?: Maybe<Scalars['String']['output']>;
  website: Scalars['String']['output'];
};

export type AffiliateSettingsInput = {
  category: AffiliateCategory;
  country: Scalars['String']['input'];
  customSubCategory?: InputMaybe<Scalars['String']['input']>;
  discovery: Scalars['String']['input'];
  language: Language;
  paypalEmail: Scalars['String']['input'];
  subCategory?: InputMaybe<AffiliateSubCategory>;
  website: Scalars['String']['input'];
};

export enum AffiliateStatus {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
  PENDING = 'PENDING',
  REJECTED = 'REJECTED',
  SUSPENDED = 'SUSPENDED'
}

export type AffiliateStatusTranslation = {
  __typename?: 'AffiliateStatusTranslation';
  active: Scalars['String']['output'];
  banned: Scalars['String']['output'];
  deleted: Scalars['String']['output'];
  pending: Scalars['String']['output'];
  rejected: Scalars['String']['output'];
  suspended: Scalars['String']['output'];
};

export enum AffiliateSubCategory {
  BUSINESS_SITE_DEVELOPMENT_CONTENT = 'BUSINESS_SITE_DEVELOPMENT_CONTENT',
  MARKETING_CONTENT = 'MARKETING_CONTENT',
  OTHER = 'OTHER',
  PLUGIN = 'PLUGIN',
  REVIEW_SITE = 'REVIEW_SITE',
  TECHNOLOGY_CONTENT = 'TECHNOLOGY_CONTENT',
  THEME = 'THEME',
  WEB_DEVELOPMENT_CONTENT = 'WEB_DEVELOPMENT_CONTENT',
  WEB_HOSTING_CONTENT = 'WEB_HOSTING_CONTENT',
  WORDPRESS_CONTENT = 'WORDPRESS_CONTENT'
}

export type AffiliateSubCategoryOption = {
  __typename?: 'AffiliateSubCategoryOption';
  label: Scalars['String']['output'];
  value: AffiliateSubCategory;
};

export type AffiliateSubCategoryTranslation = {
  __typename?: 'AffiliateSubCategoryTranslation';
  businessSiteDevelopmentContent: Scalars['String']['output'];
  marketingContent: Scalars['String']['output'];
  other: Scalars['String']['output'];
  plugin: Scalars['String']['output'];
  reviewSite: Scalars['String']['output'];
  technologyContent: Scalars['String']['output'];
  theme: Scalars['String']['output'];
  webDevelopmentContent: Scalars['String']['output'];
  webHostingContent: Scalars['String']['output'];
  wordpressContent: Scalars['String']['output'];
};

export type BareUser = User & {
  __typename?: 'BareUser';
  created: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role: Role;
};

export type ButtonsTranslation = {
  __typename?: 'ButtonsTranslation';
  details: Scalars['String']['output'];
  downloadAllImage: Scalars['String']['output'];
  exportToCsv: Scalars['String']['output'];
  saveChanges: Scalars['String']['output'];
  view: Scalars['String']['output'];
  viewReferral: Scalars['String']['output'];
};

export type Commission = {
  __typename?: 'Commission';
  amount: Scalars['Int']['output'];
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idPayment?: Maybe<Scalars['String']['output']>;
  idReferral?: Maybe<Scalars['String']['output']>;
  idUser: Scalars['String']['output'];
  payment?: Maybe<Payment>;
  paymentMethod: PaymentMethod;
  referral: Referral;
  serviceType: ServiceType;
  status: CommissionStatus;
  type: CommissionType;
  user: User;
  userRole: Role;
};

export type CommissionHistoryTranslation = {
  __typename?: 'CommissionHistoryTranslation';
  header: Scalars['String']['output'];
};

export type CommissionPaymentStatusTranslation = {
  __typename?: 'CommissionPaymentStatusTranslation';
  approved: Scalars['String']['output'];
  notApproved: Scalars['String']['output'];
  paid: Scalars['String']['output'];
  paymentPending: Scalars['String']['output'];
};

export enum CommissionStatus {
  APPROVED = 'APPROVED',
  NOT_APPROVED = 'NOT_APPROVED',
  PAID = 'PAID',
  PAYMENT_PENDING = 'PAYMENT_PENDING'
}

export type CommissionStatusTranslation = {
  __typename?: 'CommissionStatusTranslation';
  accumulating: Scalars['String']['output'];
  canceledBeforeTime: Scalars['String']['output'];
};

export enum CommissionType {
  CUSTOM = 'CUSTOM',
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING'
}

export type CommissionTypeTranslation = {
  __typename?: 'CommissionTypeTranslation';
  custom: Scalars['String']['output'];
  oneTime: Scalars['String']['output'];
  recurring: Scalars['String']['output'];
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['String']['output'];
  referrals: Stat;
};

export type ContactTranslation = {
  __typename?: 'ContactTranslation';
  buttonLink: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type CreateAffiliateLinkTranslation = {
  __typename?: 'CreateAffiliateLinkTranslation';
  affiliateLink: Scalars['String']['output'];
  affiliateUrl: Scalars['String']['output'];
  back: Scalars['String']['output'];
  cancel: Scalars['String']['output'];
  close: Scalars['String']['output'];
  createLink: Scalars['String']['output'];
  modalDescription: Scalars['String']['output'];
  modalGeneratedLink: Scalars['String']['output'];
  modalTitle: Scalars['String']['output'];
  modalUrlPlaceholder: Scalars['String']['output'];
};

export type CreateUser = {
  email: Scalars['String']['input'];
  firstName: Scalars['String']['input'];
  lastName: Scalars['String']['input'];
  password: Scalars['String']['input'];
  role: Role;
};

export type DashboardTranslation = {
  __typename?: 'DashboardTranslation';
  activeReferrals: ActiveReferralsTranslation;
  latestApprovalsTable: LatestApprovalsTableTranslation;
  latestReferralsTable: LatestReferralsTableTranslation;
  nextPayment: NextPaymentTranslation;
  paymentPending: PaymentPendingTranslation;
  referredPageViews: ReferredPageViewsTranslation;
  referredPageViewsGraph: ReferredPageViewsGraphTranslation;
  referredVisitorsGraph: ReferredVisitorsGraphTranslation;
  subscriptionsReferredGraph: SubscriptionsReferredGraphTranslation;
  totalPayments: TotalPaymentsTranslation;
};

export type DayOfTheWeekTranslation = {
  __typename?: 'DayOfTheWeekTranslation';
  friday: Scalars['String']['output'];
  monday: Scalars['String']['output'];
  saturday: Scalars['String']['output'];
  sunday: Scalars['String']['output'];
  thursday: Scalars['String']['output'];
  tuesday: Scalars['String']['output'];
  wednesday: Scalars['String']['output'];
};

export type DescriptionTranslation = {
  __typename?: 'DescriptionTranslation';
  adBlocker: Scalars['String']['output'];
  intro: Scalars['String']['output'];
  list1: Scalars['String']['output'];
  list2: Scalars['String']['output'];
  list3: Scalars['String']['output'];
  list4: Scalars['String']['output'];
  list5: Scalars['String']['output'];
};

export type DetailLabelsTranslation = {
  __typename?: 'DetailLabelsTranslation';
  commissionsPaid: Scalars['String']['output'];
  oneTimeCommissions: Scalars['String']['output'];
  payment: Scalars['String']['output'];
  paymentDate: Scalars['String']['output'];
  recurringCommissions: Scalars['String']['output'];
  totalPaid: Scalars['String']['output'];
  transactionId: Scalars['String']['output'];
};

export type DiscordTranslation = {
  __typename?: 'DiscordTranslation';
  buttonLink: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ErrorsTranslation = {
  __typename?: 'ErrorsTranslation';
  cannotBeLongerThan: Scalars['String']['output'];
  characters: Scalars['String']['output'];
  charactersLong: Scalars['String']['output'];
  correctInformation: Scalars['String']['output'];
  emailInUse: Scalars['String']['output'];
  emailInvalid: Scalars['String']['output'];
  errorLoadingCategoryList: Scalars['String']['output'];
  errorLoadingCountryList: Scalars['String']['output'];
  fieldRequired: Scalars['String']['output'];
  firstNameEmpty: Scalars['String']['output'];
  lastNameEmpty: Scalars['String']['output'];
  mustBeAtLeast: Scalars['String']['output'];
  urlInvalid: Scalars['String']['output'];
};

export type Filter = {
  andWhere?: InputMaybe<Where>;
  findByIds?: InputMaybe<Ids>;
  orWhere?: InputMaybe<Where>;
  where?: InputMaybe<Where>;
  whereBetween?: InputMaybe<WhereBetween>;
};

export type HistoricPlan = {
  __typename?: 'HistoricPlan';
  created: Scalars['String']['output'];
  name: Scalars['String']['output'];
};

export type Ids = {
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']['input']>>>;
};

export type InfoTranslation = {
  __typename?: 'InfoTranslation';
  email: Scalars['String']['output'];
  paypal: Scalars['String']['output'];
  website: Scalars['String']['output'];
};

export type LabelsTranslation = {
  __typename?: 'LabelsTranslation';
  category: Scalars['String']['output'];
  country: Scalars['String']['output'];
  customSubcategory: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  language: Scalars['String']['output'];
  lastName: Scalars['String']['output'];
  paypal: Scalars['String']['output'];
  subcategory: Scalars['String']['output'];
  website: Scalars['String']['output'];
};

export enum Language {
  DUTCH = 'DUTCH',
  ENGLISH = 'ENGLISH',
  FRENCH = 'FRENCH',
  GERMAN = 'GERMAN',
  ITALIAN = 'ITALIAN',
  JAPANESE = 'JAPANESE',
  PORTUGUESE = 'PORTUGUESE',
  SPANISH = 'SPANISH'
}

export type LatestApprovalsTableTranslation = {
  __typename?: 'LatestApprovalsTableTranslation';
  noData: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type LatestReferralsTableTranslation = {
  __typename?: 'LatestReferralsTableTranslation';
  noData: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type LoginUser = {
  email: Scalars['String']['input'];
  password: Scalars['String']['input'];
};

export type MkReferrer = {
  __typename?: 'MKReferrer';
  email?: Maybe<Scalars['String']['output']>;
  idCompany?: Maybe<Scalars['String']['output']>;
  idStripeCustomer?: Maybe<Scalars['String']['output']>;
  kaid?: Maybe<Scalars['String']['output']>;
};

export type MaterialsTranslation = {
  __typename?: 'MaterialsTranslation';
  bannersTitle: Scalars['String']['output'];
  description: DescriptionTranslation;
};

export type MenuTranslation = {
  __typename?: 'MenuTranslation';
  dashboard: Scalars['String']['output'];
  hello: Scalars['String']['output'];
  logOut: Scalars['String']['output'];
  makeLink: Scalars['String']['output'];
  payments: Scalars['String']['output'];
  promoMaterial: Scalars['String']['output'];
  referredCustomers: Scalars['String']['output'];
  referredVisitors: Scalars['String']['output'];
  resources: Scalars['String']['output'];
  settings: Scalars['String']['output'];
};

export type Meta = {
  __typename?: 'Meta';
  total: Scalars['Int']['output'];
};

export type MonthlyRevenue = {
  __typename?: 'MonthlyRevenue';
  amount: Scalars['Int']['output'];
  date: Scalars['ID']['output'];
};

export type Mutation = {
  __typename?: 'Mutation';
  addView?: Maybe<Scalars['Boolean']['output']>;
  adminPatchAffiliate: Affiliate;
  approveAffiliate: Affiliate;
  banAffiliate: Affiliate;
  createAffiliate: Affiliate;
  createBillingThresholdCommission: Commission;
  createCommission: Commission;
  createMkReferral: Referral;
  createServiceCommission: Commission;
  createTag: Tag;
  createUser?: Maybe<User>;
  deleteAffiliate: Affiliate;
  getMkReferralCode: Scalars['String']['output'];
  issuePasswordResetLink?: Maybe<Scalars['Boolean']['output']>;
  login?: Maybe<User>;
  logout?: Maybe<User>;
  makePayment: Payment;
  markPaymentsPaid: Scalars['Boolean']['output'];
  markPaymentsPending: PaginatedPayment;
  patchAffiliate: Affiliate;
  patchCommission: Commission;
  payCommission: Commission;
  reinstateAffiliate: Affiliate;
  reinstatePayments: Affiliate;
  rejectAffiliate: Affiliate;
  resetPassword?: Maybe<User>;
  suspendAffiliate: Affiliate;
  suspendPayments: Affiliate;
};


export type MutationAddViewArgs = {
  idReferral: Scalars['ID']['input'];
  location: Scalars['String']['input'];
  referrer?: InputMaybe<Scalars['String']['input']>;
  user: Scalars['String']['input'];
};


export type MutationAdminPatchAffiliateArgs = {
  id: Scalars['ID']['input'];
  info?: InputMaybe<PatchAffiliateInfoInput>;
  profile?: InputMaybe<PatchAffiliateProfile>;
  settings?: InputMaybe<PatchAffiliateSettingsInput>;
  tags?: InputMaybe<Array<Scalars['String']['input']>>;
};


export type MutationApproveAffiliateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationBanAffiliateArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationCreateAffiliateArgs = {
  user: AffiliateInput;
};


export type MutationCreateBillingThresholdCommissionArgs = {
  amount: Scalars['Int']['input'];
  created: Scalars['String']['input'];
  idStripeCustomer: Scalars['ID']['input'];
  mkrc: Scalars['ID']['input'];
};


export type MutationCreateCommissionArgs = {
  amount: Scalars['Int']['input'];
  idReferral: Scalars['ID']['input'];
  idUser: Scalars['ID']['input'];
  type: CommissionType;
};


export type MutationCreateMkReferralArgs = {
  created: Scalars['String']['input'];
  idStripeCustomer: Scalars['ID']['input'];
  mkrc: Scalars['ID']['input'];
};


export type MutationCreateServiceCommissionArgs = {
  amount: Scalars['Int']['input'];
  created: Scalars['String']['input'];
  idStripeCustomer: Scalars['ID']['input'];
  mkrc: Scalars['ID']['input'];
};


export type MutationCreateTagArgs = {
  tag: NewTag;
};


export type MutationCreateUserArgs = {
  user: CreateUser;
};


export type MutationDeleteAffiliateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationGetMkReferralCodeArgs = {
  referralCodeRequest: ReferralCodeRequest;
};


export type MutationIssuePasswordResetLinkArgs = {
  email: Scalars['String']['input'];
};


export type MutationLoginArgs = {
  user: LoginUser;
};


export type MutationLogoutArgs = {
  allSessions?: InputMaybe<Scalars['Boolean']['input']>;
};


export type MutationMakePaymentArgs = {
  idPayment: Scalars['ID']['input'];
  idPaypalTransaction: Scalars['String']['input'];
};


export type MutationMarkPaymentsPaidArgs = {
  paymentsCSV: Scalars['String']['input'];
};


export type MutationMarkPaymentsPendingArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type MutationPatchAffiliateArgs = {
  profile?: InputMaybe<PatchAffiliateProfile>;
  settings?: InputMaybe<PatchAffiliateSettingsInput>;
};


export type MutationPatchCommissionArgs = {
  amount: Scalars['Int']['input'];
  idCommission: Scalars['ID']['input'];
  status: CommissionStatus;
  type: CommissionType;
};


export type MutationPayCommissionArgs = {
  idCommission: Scalars['ID']['input'];
};


export type MutationReinstateAffiliateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationReinstatePaymentsArgs = {
  id: Scalars['ID']['input'];
};


export type MutationRejectAffiliateArgs = {
  id: Scalars['ID']['input'];
};


export type MutationResetPasswordArgs = {
  jwt: Scalars['String']['input'];
  password: Scalars['String']['input'];
};


export type MutationSuspendAffiliateArgs = {
  id: Scalars['ID']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
};


export type MutationSuspendPaymentsArgs = {
  id: Scalars['ID']['input'];
};

export type NewTag = {
  description: Scalars['String']['input'];
  name: Scalars['String']['input'];
};

export type NextPaymentTranslation = {
  __typename?: 'NextPaymentTranslation';
  title: Scalars['String']['output'];
  tooltipText: Scalars['String']['output'];
  tooltipTitle: Scalars['String']['output'];
};

export type NoDataTranslation = {
  __typename?: 'NoDataTranslation';
  na: Scalars['String']['output'];
};

export type Page = {
  limit: Scalars['Int']['input'];
  offset?: InputMaybe<Scalars['Int']['input']>;
};

export type PaginatedAction = {
  __typename?: 'PaginatedAction';
  meta: Meta;
  nodes?: Maybe<Array<Maybe<Action>>>;
};

export type PaginatedAffiliate = {
  __typename?: 'PaginatedAffiliate';
  meta: Meta;
  nodes: Array<Affiliate>;
};

export type PaginatedAffiliateApprovedWithReferral = {
  __typename?: 'PaginatedAffiliateApprovedWithReferral';
  meta: Meta;
  nodes: Array<AffiliateApprovedWithReferral>;
};

export type PaginatedCommission = {
  __typename?: 'PaginatedCommission';
  meta: Meta;
  nodes: Array<Commission>;
};

export type PaginatedMkReferrers = {
  __typename?: 'PaginatedMKReferrers';
  meta: Meta;
  nodes: Array<MkReferrer>;
};

export type PaginatedMonthlyPayment = {
  __typename?: 'PaginatedMonthlyPayment';
  meta: Meta;
  nodes: Array<PaymentMonthly>;
};

export type PaginatedPayment = {
  __typename?: 'PaginatedPayment';
  meta: Meta;
  nodes: Array<Payment>;
};

export type PaginatedReferral = {
  __typename?: 'PaginatedReferral';
  meta: Meta;
  nodes: Array<Referral>;
};

export type PaginatedReferredPageview = {
  __typename?: 'PaginatedReferredPageview';
  meta: Meta;
  nodes: Array<ReferredPageview>;
};

export type PaginatedReferredUrl = {
  __typename?: 'PaginatedReferredUrl';
  meta: Meta;
  nodes: Array<Maybe<ReferredUrl>>;
};

export type PaginatedReferredVisitor = {
  __typename?: 'PaginatedReferredVisitor';
  meta: Meta;
  nodes: Array<ReferredVisitor>;
};

export type PaginatedSeller = {
  __typename?: 'PaginatedSeller';
  meta: Meta;
  nodes?: Maybe<Array<Maybe<Seller>>>;
};

export type PaginatedSumCommissions = {
  __typename?: 'PaginatedSumCommissions';
  meta: Meta;
  nodes: Array<Maybe<SumCommission>>;
};

export type PaginatedUser = {
  __typename?: 'PaginatedUser';
  meta: Meta;
  nodes?: Maybe<Array<Maybe<User>>>;
};

export type PaginatedWeeklyCount = {
  __typename?: 'PaginatedWeeklyCount';
  meta: Meta;
  nodes: Array<WeeklyCount>;
};

export type PatchAffiliateInfoInput = {
  adultContent?: InputMaybe<Scalars['Boolean']['input']>;
  agreeToCoupons?: InputMaybe<Scalars['Boolean']['input']>;
  agreeToNewsletter?: InputMaybe<Scalars['Boolean']['input']>;
  agreeToNoPPC?: InputMaybe<Scalars['Boolean']['input']>;
  agreeToTermination?: InputMaybe<Scalars['Boolean']['input']>;
  agreeToTerms?: InputMaybe<Scalars['Boolean']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  existingCustomer?: InputMaybe<Scalars['Boolean']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  useAddons?: InputMaybe<Scalars['Boolean']['input']>;
  useCoupons?: InputMaybe<Scalars['Boolean']['input']>;
};

export type PatchAffiliateProfile = {
  email?: InputMaybe<Scalars['String']['input']>;
  firstName?: InputMaybe<Scalars['String']['input']>;
  lastName?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
};

export type PatchAffiliateSettingsInput = {
  category?: InputMaybe<AffiliateCategory>;
  country?: InputMaybe<Scalars['String']['input']>;
  customSubCategory?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Language>;
  mkCookie?: InputMaybe<Scalars['Boolean']['input']>;
  paypalEmail?: InputMaybe<Scalars['String']['input']>;
  subCategory?: InputMaybe<AffiliateSubCategory>;
  website?: InputMaybe<Scalars['String']['input']>;
};

export type Payment = {
  __typename?: 'Payment';
  amount: Scalars['Int']['output'];
  commissions: PaginatedCommission;
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idPaypalTransaction?: Maybe<Scalars['String']['output']>;
  idUser: Scalars['String']['output'];
  paidAt?: Maybe<Scalars['String']['output']>;
  status: PaymentStatus;
  user: User;
};


export type PaymentCommissionsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export enum PaymentMethod {
  CASH = 'CASH',
  CREDIT = 'CREDIT'
}

export type PaymentMonthly = {
  __typename?: 'PaymentMonthly';
  amount: Scalars['Int']['output'];
  count: Scalars['Int']['output'];
  paidMonthYear: Scalars['String']['output'];
};

export type PaymentPendingTranslation = {
  __typename?: 'PaymentPendingTranslation';
  title: Scalars['String']['output'];
};

export enum PaymentStatus {
  FAILED = 'FAILED',
  ON_HOLD = 'ON_HOLD',
  PAID = 'PAID',
  PENDING = 'PENDING',
  UPCOMING = 'UPCOMING'
}

export type PaymentStatusTranslation = {
  __typename?: 'PaymentStatusTranslation';
  failed: Scalars['String']['output'];
  onHold: Scalars['String']['output'];
  paid: Scalars['String']['output'];
  pending: Scalars['String']['output'];
  upcoming: Scalars['String']['output'];
};

export type PaymentTranslation = {
  __typename?: 'PaymentTranslation';
  detailLabels: DetailLabelsTranslation;
  noData: Scalars['String']['output'];
};

export type PaymentsTranslation = {
  __typename?: 'PaymentsTranslation';
  noData: Scalars['String']['output'];
};

export type Period = {
  end: Scalars['String']['input'];
  start: Scalars['String']['input'];
};

export type PlaceholderTranslation = {
  __typename?: 'PlaceholderTranslation';
  select: Scalars['String']['output'];
};

export type PromoTranslation = {
  __typename?: 'PromoTranslation';
  buttonText: Scalars['String']['output'];
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Query = {
  __typename?: 'Query';
  _entities: Array<Maybe<_Entity>>;
  _service: _Service;
  actions?: Maybe<PaginatedAction>;
  affiliateCategories: Array<AffiliateCategoryOption>;
  affiliateCountries: Array<AffiliateCountryOption>;
  affiliateEmails?: Maybe<Array<Scalars['String']['output']>>;
  affiliateReferralsByWeek: PaginatedWeeklyCount;
  affiliates: PaginatedAffiliate;
  affiliatesApprovedOverPeriodWithReferrals: PaginatedAffiliateApprovedWithReferral;
  applicationsByWeek: PaginatedWeeklyCount;
  commissions: PaginatedCommission;
  mkCodeReferrers: PaginatedMkReferrers;
  monthlyRevenue: Array<MonthlyRevenue>;
  payments: PaginatedPayment;
  paymentsMonthly: PaginatedMonthlyPayment;
  paymentsSum: Scalars['Int']['output'];
  paymentsWithAffiliateSettings: PaginatedPayment;
  referrals: PaginatedReferral;
  referredVisitors: PaginatedReferredVisitor;
  sellers?: Maybe<PaginatedSeller>;
  tags?: Maybe<Array<Tag>>;
  user?: Maybe<User>;
  users?: Maybe<PaginatedUser>;
  validPasswordReset: Scalars['Boolean']['output'];
};


export type Query_EntitiesArgs = {
  representations: Array<Scalars['_Any']['input']>;
};


export type QueryActionsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryAffiliateEmailsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};


export type QueryAffiliateReferralsByWeekArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryAffiliatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryAffiliatesApprovedOverPeriodWithReferralsArgs = {
  page?: InputMaybe<Page>;
  period: Period;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryApplicationsByWeekArgs = {
  action: Scalars['String']['input'];
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryCommissionsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryMkCodeReferrersArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryMonthlyRevenueArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};


export type QueryPaymentsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryPaymentsMonthlyArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryPaymentsSumArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};


export type QueryPaymentsWithAffiliateSettingsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryReferralsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryReferredVisitorsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QuerySellersArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryTagsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};


export type QueryUsersArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};


export type QueryValidPasswordResetArgs = {
  jwt: Scalars['String']['input'];
};

export type Referral = {
  __typename?: 'Referral';
  commissions: PaginatedCommission;
  created: Scalars['String']['output'];
  hasCompletedQualification: Scalars['Boolean']['output'];
  id: Scalars['ID']['output'];
  idStripeCustomer: Scalars['String']['output'];
  idUser: Scalars['String']['output'];
  isActive: Scalars['Boolean']['output'];
  stripe: StripeReferrals;
  user: User;
};


export type ReferralCommissionsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type ReferralCodeRequest = {
  idCompany: Scalars['String']['input'];
  idStripeCustomer: Scalars['String']['input'];
};

export type ReferralLogTranslation = {
  __typename?: 'ReferralLogTranslation';
  commissionApproved: Scalars['String']['output'];
  commissionPaid: Scalars['String']['output'];
  customerSwitched: Scalars['String']['output'];
  header: Scalars['String']['output'];
  justApproved: Scalars['String']['output'];
  justPaid: Scalars['String']['output'];
  planChanged: Scalars['String']['output'];
  referralCongratulations: Scalars['String']['output'];
  referralCreated: Scalars['String']['output'];
  thanksForCustomer: Scalars['String']['output'];
};

export enum ReferralType {
  AFFILIATE = 'AFFILIATE',
  MK_CODE = 'MK_CODE'
}

export type ReferralsTranslation = {
  __typename?: 'ReferralsTranslation';
  noReferrals: Scalars['String']['output'];
  referralDetailsHeader: Scalars['String']['output'];
};

export type ReferredPageViewsGraphTranslation = {
  __typename?: 'ReferredPageViewsGraphTranslation';
  title: Scalars['String']['output'];
};

export type ReferredPageViewsTranslation = {
  __typename?: 'ReferredPageViewsTranslation';
  title: Scalars['String']['output'];
};

export type ReferredPageview = {
  __typename?: 'ReferredPageview';
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idReferredVisitor: Scalars['String']['output'];
  idUser: Scalars['String']['output'];
  referrer?: Maybe<Scalars['String']['output']>;
  target: Scalars['String']['output'];
};

export type ReferredUrl = {
  __typename?: 'ReferredUrl';
  count?: Maybe<Scalars['Int']['output']>;
  referrer?: Maybe<Scalars['String']['output']>;
};

export type ReferredVisitor = {
  __typename?: 'ReferredVisitor';
  created: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  idUser: Scalars['String']['output'];
  pageviews: PaginatedReferredPageview;
};


export type ReferredVisitorPageviewsArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  page?: InputMaybe<Page>;
  sort?: InputMaybe<Array<InputMaybe<Sort>>>;
};

export type ReferredVisitorsGraphTranslation = {
  __typename?: 'ReferredVisitorsGraphTranslation';
  title: Scalars['String']['output'];
};

export type ReferredVisitorsTranslation = {
  __typename?: 'ReferredVisitorsTranslation';
  noData: Scalars['String']['output'];
  noReferrer: Scalars['String']['output'];
};

export type ResourcesTranslation = {
  __typename?: 'ResourcesTranslation';
  about: AboutTranslation;
  academy: AcademyTranslation;
  contact: ContactTranslation;
  discord: DiscordTranslation;
  promo: PromoTranslation;
  terms: TermsTranslation;
};

export enum Role {
  ADMIN_AFFILIATE = 'ADMIN_AFFILIATE',
  ADMIN_SELLER = 'ADMIN_SELLER',
  AFFILIATE = 'AFFILIATE',
  MK_REFERRER = 'MK_REFERRER',
  SELLER = 'SELLER',
  SUPERADMIN = 'SUPERADMIN'
}

export type Seller = User & {
  __typename?: 'Seller';
  created: Scalars['String']['output'];
  email: Scalars['String']['output'];
  firstName: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  lastName: Scalars['String']['output'];
  role: Role;
  settings: SellerSettings;
};

export type SellerSettings = {
  __typename?: 'SellerSettings';
  idHubspot: Scalars['String']['output'];
  status: SellerStatus;
};

export enum SellerStatus {
  ACTIVE = 'ACTIVE',
  SUSPENDED = 'SUSPENDED'
}

export enum ServiceType {
  PAY_AS_YOU_GO = 'PAY_AS_YOU_GO',
  SUBSCRIPTION = 'SUBSCRIPTION'
}

export type ServiceTypeTranslation = {
  __typename?: 'ServiceTypeTranslation';
  payAsYouGo: Scalars['String']['output'];
  subscription: Scalars['String']['output'];
};

export type SettingsTranslation = {
  __typename?: 'SettingsTranslation';
  errors: ErrorsTranslation;
  info: InfoTranslation;
  labels: LabelsTranslation;
  placeholders: PlaceholderTranslation;
  toast: ToastTranslations;
};

export type Sort = {
  column: Scalars['String']['input'];
  order: Scalars['String']['input'];
};

export type Stat = {
  __typename?: 'Stat';
  billingThresholdCommissions: Scalars['Int']['output'];
  creditEarned: Scalars['Int']['output'];
  id: Scalars['ID']['output'];
  referrals: Scalars['Int']['output'];
  serviceCommissions: Scalars['Int']['output'];
};

export type StatusTranslation = {
  __typename?: 'StatusTranslation';
  active: Scalars['String']['output'];
  canceled: Scalars['String']['output'];
};

export type StripeReferrals = {
  __typename?: 'StripeReferrals';
  description: Scalars['String']['output'];
  email: Scalars['String']['output'];
  id: Scalars['String']['output'];
  plan: Scalars['String']['output'];
  planHistory: Array<HistoricPlan>;
};

export type SubscriptionsReferredGraphTranslation = {
  __typename?: 'SubscriptionsReferredGraphTranslation';
  title: Scalars['String']['output'];
};

export type SumCommission = {
  __typename?: 'SumCommission';
  idUser?: Maybe<Scalars['String']['output']>;
  status?: Maybe<CommissionStatus>;
  totalCommission?: Maybe<Scalars['Int']['output']>;
};

export type TableColsTranslation = {
  __typename?: 'TableColsTranslation';
  actions: Scalars['String']['output'];
  amount: Scalars['String']['output'];
  approved: Scalars['String']['output'];
  commissionId: Scalars['String']['output'];
  commissions: Scalars['String']['output'];
  count: Scalars['String']['output'];
  created: Scalars['String']['output'];
  date: Scalars['String']['output'];
  id: Scalars['String']['output'];
  oneTime: Scalars['String']['output'];
  oneTimeCommission: Scalars['String']['output'];
  plan: Scalars['String']['output'];
  planTooltip: Scalars['String']['output'];
  recurring: Scalars['String']['output'];
  referredOn: Scalars['String']['output'];
  serviceType: Scalars['String']['output'];
  serviceTypeTooltip: Scalars['String']['output'];
  status: Scalars['String']['output'];
  totalPaid: Scalars['String']['output'];
  transaction: Scalars['String']['output'];
  type: Scalars['String']['output'];
  url: Scalars['String']['output'];
  view: Scalars['String']['output'];
};

export type Tag = {
  __typename?: 'Tag';
  affiliates?: Maybe<Array<Affiliate>>;
  description: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};


export type TagAffiliatesArgs = {
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
};

export type TermsTranslation = {
  __typename?: 'TermsTranslation';
  buttonLink: Scalars['String']['output'];
  buttonText: Scalars['String']['output'];
  content: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type Toast = {
  __typename?: 'Toast';
  message: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

export type ToastTranslations = {
  __typename?: 'ToastTranslations';
  success?: Maybe<Toast>;
};

export type TotalPaymentsTranslation = {
  __typename?: 'TotalPaymentsTranslation';
  title: Scalars['String']['output'];
};

export type Translation = {
  __typename?: 'Translation';
  accountSuspended: Scalars['String']['output'];
  affiliateCategory: AffiliateCategoryTranslation;
  affiliateStatus: AffiliateStatusTranslation;
  affiliateSubCategory: AffiliateSubCategoryTranslation;
  buttons: ButtonsTranslation;
  commissionHistory: CommissionHistoryTranslation;
  commissionPaymentStatus: CommissionPaymentStatusTranslation;
  commissionStatus: CommissionStatusTranslation;
  commissionType: CommissionTypeTranslation;
  createAffiliateLink: CreateAffiliateLinkTranslation;
  dashboard: DashboardTranslation;
  dayOfTheWeek: DayOfTheWeekTranslation;
  materials: MaterialsTranslation;
  menu: MenuTranslation;
  noData: NoDataTranslation;
  payment: PaymentTranslation;
  paymentStatus: PaymentStatusTranslation;
  payments: PaymentsTranslation;
  referralLog: ReferralLogTranslation;
  referrals: ReferralsTranslation;
  referredVisitors: ReferredVisitorsTranslation;
  resources: ResourcesTranslation;
  serviceType: ServiceTypeTranslation;
  settings: SettingsTranslation;
  status: StatusTranslation;
  tableCols: TableColsTranslation;
};

export type User = {
  created: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  firstName?: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  lastName?: Maybe<Scalars['String']['output']>;
  role: Role;
};

export type WeeklyCount = {
  __typename?: 'WeeklyCount';
  count: Scalars['Int']['output'];
  week: Scalars['String']['output'];
  week_no: Scalars['Int']['output'];
};

export type Where = {
  col?: InputMaybe<Scalars['String']['input']>;
  expr?: InputMaybe<Scalars['String']['input']>;
  filter?: InputMaybe<Array<InputMaybe<Filter>>>;
  op?: InputMaybe<Scalars['String']['input']>;
};

export type WhereBetween = {
  col?: InputMaybe<Scalars['String']['input']>;
  range?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

export type _Entity = Company | Stat;

export type _Service = {
  __typename?: '_Service';
  sdl?: Maybe<Scalars['String']['output']>;
};


      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {
    "User": [
      "Affiliate",
      "BareUser",
      "Seller"
    ],
    "_Entity": [
      "Company",
      "Stat"
    ]
  }
};
      export default result;
    